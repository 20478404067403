.floating-dock {
    background-color: #010622;
    height: 80px;
    opacity: 1;
    box-shadow: none;
    transform: translateY(0);
    transition: background-color 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                height 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                box-shadow 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    border-bottom: 2px solid rgb(255, 100, 103); /* Already in place */
}

.floating-dock-scrolled {
    background-color: #010622;
    height: 60px;
    opacity: 0.95;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
    transition: background-color 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                height 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                box-shadow 0.8s cubic-bezier(0.4, 0, 0.2, 1),
                transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.main-menu {
    margin-right: 10vw;
}
.main-menu p {
    padding: 2rem 1rem 1rem;
    color: #FF6467;
    text-align: left;
    text-transform: uppercase;
    font: normal normal bold 14px/17px 'Signika';
    letter-spacing: 2.8px;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
}
.main-menu p:hover {
    color: #fff;
}
