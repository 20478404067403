.gradient-border-box-video {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.05), hsla(0, 0%, 100%, 0.05)) 0 0 no-repeat padding-box;
    overflow: hidden;
  }
  
  .gradient-border-box-video-chapters {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.05), hsla(0, 0%, 100%, 0.05)) 0 0 no-repeat padding-box;
    border: 1px solid transparent;
    overflow: hidden;
    border-image: linear-gradient(180deg, rgba(53, 136, 116, 0.8), rgba(49, 69, 105, 0)) 1;
    border-image-slice: 1;
  }
  
  .video-meta {
    font: normal normal 300 12px / 16px Signika;
    letter-spacing: 2px;
    opacity: 0.5;
    text-transform: uppercase;
  }
  
  @media (min-width: 768px) {
    .video-meta {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 2.8px;
    }
  }
  
  .video-description {
    text-align: left;
    font: normal normal 300 16px / 24px Basic Sans;
    letter-spacing: -0.38px;
    color: #f2f2f2;
    opacity: 0.8;
  }
  
  @media (min-width: 768px) {
    .video-description {
      font-size: 20px;
      line-height: 32px;
    }
  }
  
  .video-description button {
    text-align: left;
    font: normal normal bold 12px / 16px 'Signika';
    font-weight: bold;
    letter-spacing: 1.5px;
  }
  
  @media (min-width: 768px) {
    .video-description button {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1.8px;
    }
  }
  
  .video-chapters {
    text-align: left;
    font: normal normal bold 16px / 20px 'Signika';
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    text-transform: uppercase;
    opacity: 0.6;
  }
  
  @media (min-width: 768px) {
    .video-chapters {
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 2.4px;
    }
  }
  
  .video-title {
    color: #FF6467;
    text-align: left;
    text-transform: uppercase;
    font: normal normal bold 16px / 18px 'Signika';
    letter-spacing: 2.5px;
    margin-bottom: 1.5rem;
    line-height: 2rem;
  }
  
  @media (min-width: 768px) {
    .video-title {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
  }
  
  .list-disc li {
    font: normal normal bold 14px / 18px 'Signika';
    color: #fff;
  }
  
  @media (min-width: 768px) {
    .list-disc li {
      font-size: 17px;
      line-height: 19px;
    }
  }
  