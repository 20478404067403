#voices {
    font-family: 'Basic Sans', sans-serif;
    opacity: 0.8;
}

.bh-logo {
    text-align: left;
    font-family: 'Acherus Militant Bold', sans-serif;
    letter-spacing: -1.15px;
    /* color: #fff; */
    margin-bottom: 1rem;
}

.voices-title {
    text-align: left;
    font: normal normal 300  Signika;
    letter-spacing: -1.15px;
    color: #fff;
    margin-bottom: 1rem;
}
.voices-title .text-center {

    background: rgba(255, 255, 255, .13);
}