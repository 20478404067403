.home {
    font-family: 'Basic Sans', sans-serif;
    opacity: 0.8;
}

.home-title {
    text-align: left;
    font: normal normal 300  Signika;
    letter-spacing: -1.15px;
    color: #fff;
    margin-bottom: 1rem;
}

.custom-hover:hover .icon {
    color: #FF6467; 
}

.custom-hover:hover p {
    color: #FF6467; 
}

.net-button {
    color:"white"
}

.chart-sub-title {
    text-align: left;
    font: normal normal normal 12px/17px "Signika";
    letter-spacing: 2.4px;
    color: #fff;
    text-transform: uppercase;
    opacity: .5;
}

.chart-sub-value {
    text-align: left; 
    font: normal normal 600 24px/40px 'Source Sans 3';
    letter-spacing: -0.38px;
    opacity: 1;
}

.drawer-title {
    font: normal normal 600 24px/32px 'Source Sans 3';
    letter-spacing: -0.38px;
}

.stake-now-button {
    font: normal normal bold 14px/17px 'Signika';
    letter-spacing: 2.8px;
}

.dollar-symbol {
    border-radius: 100px;
    background-color: #16c784;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dollar-symbol-white {
    border-radius: 100px;
    background-color: #000;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.green-button-value {
    text-align: left;
    font: normal normal 600 24px / 64px Signika;
    letter-spacing: -.38px;
}

.home-banner {
    
    border: 1px solid transparent;
    overflow: hidden;
    -o-border-image: linear-gradient(180deg, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1;
    border-image: linear-gradient(180deg, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1;
    border-image-slice: 1;
    /* margin: 1rem calc(10% + 5px); */
    
}

.banner-tile {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, .05), hsla(0, 0%, 100%, .05)) 0 0 no-repeat padding-box;
    cursor: pointer;
    height: 100%;
    border-left: 1px solid transparent;
    -o-border-image: linear-gradient(180deg, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1;
    border-image: linear-gradient(180deg, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1;
}

.banner-tile:hover {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, .015), hsla(0, 0%, 50%, .015)) 0 0 no-repeat padding-box;
}

/* Adjust border and layout for mobile */
@media (max-width: 768px) {
    .banner-tile {
        border-left: none; /* Remove left border on mobile */
        border-top: 1px solid transparent; /* Add top border for mobile */
        -o-border-image: linear-gradient(to right, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1; /* Change border direction */
        border-image: linear-gradient(to right, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1; /* Change border direction */
    }

    .banner-tile:first-child {
        border-top: none; /* Remove top border from the first item */
    }
}

/* Adjustments for the first tile */
.banner-tile:first-child {
    border-left: none;
}

#nt-example1-container {
    color: #fff;
    height: 3rem;
    padding-top: 1rem;
}

#nt-example1-container {
  text-align: center;
  border-top: 1px solid rgba(255,100, 103, .8);
}

#nt-example1-container i {
  font-size: 80px;
  margin: 1.25rem;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#nt-example1 div {
    text-transform: uppercase;
    font: normal normal bold 14px/17px 'Signika';
    letter-spacing: 2.8px;
    opacity: 0.6;
}
#nt-example1 div a {
    text-transform: uppercase;
    color: rgb(255, 100, 103);
    cursor: pointer;
}
