.about-title {
    font: normal normal 300 3.5rem/4rem Basic Sans;
    letter-spacing: 0px;
    opacity: 1;
    color: #fff;
}

.about-icon-title {
    text-align: center;
    font: normal normal 600 1.5rem/1.67rem Basic Sans;
    letter-spacing: 0px;
    color:#fff;
    margin-bottom: 1rem;
}

.about-icon-subtitle {
    text-align: center;
    font: normal normal normal 1rem/1.25rem Basic Sans;
    letter-spacing: 2.8px;
    color: #FF6467;
    text-transform: uppercase;
}


.profile-card {
    position: relative;
    text-align: center;
}


.hover-corner-about {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease-out; /* Adding transition for scale effect */
    z-index: 5;
}

/* Adjust the corners to be outside of the image */
.top-left-about {
    top: -20px;
    left: -20px;
    border-bottom: none;
    border-right: none;
    transform: scale(0); /* Start with scale(0) */
}

.top-right-about {
    top: -20px;
    right: -20px;
    border-bottom: none;
    border-left: none;
    transform: scale(0); /* Start with scale(0) */
}

.bottom-left-about {
    bottom: -20px;
    left: -20px;
    border-top: none;
    border-right: none;
    transform: scale(0); /* Start with scale(0) */
}

.bottom-right-about {
    bottom: -20px;
    right: -20px;
    border-top: none;
    border-left: none;
    transform: scale(0); /* Start with scale(0) */
}

/* Ensure the corners are visible and scale up on hover */
.profile-card:hover .hover-corner-about {
    opacity: 1;
    transform: scale(1); /* Scale up the corner when hovered */
}



/* Avatar image */
.profile-img {
    border-radius: 50%;
    position: relative;
    object-fit: cover;
    z-index: 1; 
    clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
}

/* Hover transition for the text */
.profile-card:hover .about-icon-title,
.profile-card:hover .about-icon-subtitle {
    transform: translateY(-10px);
    transition: transform 0.3s ease;
}

/* Description animation */
.description {
    width: 70%;
    text-align: center;
    opacity: 0;
    font: normal normal 300 20px/32px Basic Sans;
    color: #fff;
    transition: opacity 0.3s ease;
}

.profile-card:hover .description {
    opacity: 0.75;
}

.hvr-rectangle-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    background: #e1e1e1;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-rectangle-out:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098D1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
    color: white;
  }
  .hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
    -webkit-transform: scale(1);
    transform: scale(1);
  }