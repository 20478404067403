/* VideoList.css */
.gradient-border-box {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.05), hsla(0, 0%, 100%, 0.05)) 0 0 no-repeat padding-box;
    border: 1px solid transparent;
    overflow: hidden;
    border-image: linear-gradient(180deg, rgba(53, 136, 116, 0.8), rgba(49, 69, 105, 0)) 1;
    border-image-slice: 1;
  }

.video-list-title{
    font: normal normal normal 24px / 29px 'Signika';
    letter-spacing: 1px;
    color: #fff
}

.video-list-meta{
    font: normal normal 300 15px / 18px Signika;
    letter-spacing: 2.8px;
    opacity: .5;
    text-transform: uppercase;
}