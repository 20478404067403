.contact-title {
    text-align: left;
    font: normal normal 300 2rem/2.5rem Basic Sans; /* Adjusted for mobile */
    letter-spacing: -0.38px;
    opacity: 1;
    color: #fff;
}

@media (min-width: 768px) {
    .contact-title {
        font-size: 4.5rem;
        line-height: 5rem;
    }
}

.contact-form-title {
    text-align: left;
    font: normal normal 300 1.25rem/1.5rem Basic Sans;
    letter-spacing: -0.38px;
    opacity: 1;
    color: #fff;
}

@media (min-width: 768px) {
    .contact-form-title {
        font-size: 1.5rem;
        line-height: 1.67rem;
    }
}

.error-border {
    border: 1px solid red !important;
}

.contact-form-sub-title {
    text-align: left;
    font: normal normal 300 1rem/1.25rem Basic Sans; /* Adjusted for mobile */
    letter-spacing: -0.38px;
    opacity: 0.5;
    color: #fff;
}

@media (min-width: 768px) {
    .contact-form-sub-title {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

.contact-updates-title {
    text-align: center;
    font: normal normal 300 2rem/2.5rem Basic Sans; /* Adjusted for mobile */
    letter-spacing: -0.38px;
    opacity: 1;
    color: #fff;
}

@media (min-width: 768px) {
    .contact-updates-title {
        font-size: 3.5rem;
        line-height: 4rem;
    }
}

.contact-updates-content {
    text-align: center;
    font: normal normal 300 1.25rem/1.75rem Basic Sans; /* Adjusted for mobile */
    letter-spacing: -0.51px;
    color: #FFFFFF;
    opacity: 1;
}

@media (min-width: 768px) {
    .contact-updates-content {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

.contact-updates-content-bh {
    text-align: center;
    font: normal normal 300 1.25rem/1.75rem Basic Sans; /* Adjusted for mobile */
    letter-spacing: -0.51px;
    color: #FFFFFF;
    opacity: 0.75;
}

@media (min-width: 768px) {
    .contact-updates-content-bh {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

.gradient-border-box-video-contact {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.05), hsla(0, 0%, 100%, 0.05)) 0 0 no-repeat padding-box;
    border: 1px solid transparent;
    overflow: hidden;
    border-image: linear-gradient(180deg, rgba(53, 136, 116, 0.8), rgba(49, 69, 105, 0)) 1;
    border-image-slice: 1;
    padding: 2rem; /* Adjusted for mobile */
}

@media (min-width: 768px) {
    .gradient-border-box-video-contact {
        padding: 4rem;
    }
}

.gradient-border-box-video-contact-input {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.05), hsla(0, 0%, 100%, 0.05)) 0 0 no-repeat padding-box;
    border: 1px solid transparent;
    overflow: hidden;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)) 1;
    color: rgba(255, 255, 255, 1);
    border-image-slice: 1;
    padding: 12px; /* Adjusted for mobile */
    font: normal normal 300 1rem/1.25rem Basic Sans; /* Adjusted for mobile */
    letter-spacing: -0.38px;
}

@media (min-width: 768px) {
    .gradient-border-box-video-contact-input {
        padding: 16px;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

.gradient-border-box-video-contact-input::placeholder {
    font: normal normal 300 1rem/1.25rem Basic Sans; /* Adjusted for mobile */
    letter-spacing: -0.38px;
    color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
    .gradient-border-box-video-contact-input::placeholder {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}
